import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SectionHeader from "./SectionHeader";
import Image from "react-bootstrap/Image";
import "./Features3.scss";
import Consumer from "../util/CartContext";

import * as firebase from "firebase/app";
import "firebase/database";

export default class Features3 extends React.Component {
  constructor(props) {
    super(props);
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: "AIzaSyB4kaxKiEVS_C_TpjeEg7xHxhvmTbUUyJI",
        authDomain: "conceptos-5774b.firebaseapp.com",
        databaseURL: "https://conceptos-5774b.firebaseio.com",
        projectId: "conceptos-5774b",
        storageBucket: "conceptos-5774b.appspot.com",
        messagingSenderId: "370910585651",
        appId: "1:370910585651:web:24fec9ccf3cf7dd3021446",
      });
    }
    this.state = {
      list: [],
    };
  }

  componentDidMount() {
    let itemsArray = this.state.list;
    firebase
      .database()
      .ref("/products")
      .once("value", (data) => {
        for (let item in data.val()) {
          console.log(item);
          // if (item === "lamina") {
          //   continue;
          // }
          itemsArray.push({
            code: data.val()[item].code,
            title: data.val()[item].name,
            description: data.val()[item].description,
            image: data.val()[item].imageURL,
            price: !isNaN(data.val()[item].price)
              ? data.val()[item].price.toFixed(2)
              : null,
            product: true,
            lamina: data.val()[item].lamina || false,
            pdfURL: data.val()[item].pdfURL || false,
          });
        }
        console.log(itemsArray);

        this.setState({ list: itemsArray });
      });
  }

  render() {
    return (
      <Consumer>
        {(ctx) => {
          return (
            <div className="Features3">
              {this.state.list.map((item, key) => (
                <Row className="align-items-center" key={key}>
                  <Col xs={12} lg={6}>
                    <SectionHeader
                      title={item.title}
                      subtitle={item.description}
                      price={!item.lamina ? item.price : ctx.laminaPrice}
                      code={item.code}
                      lamina={item.lamina}
                      pdfurl={item.pdfURL}
                      spaced={true}
                      size={3}
                      className="text-center text-lg-left"
                      product={item.product}
                    ></SectionHeader>
                  </Col>
                  <Col>
                    <figure className="Features3__image-container">
                      <Image
                        src={item.image}
                        alt={item.title}
                        fluid={true}
                      ></Image>
                    </figure>
                  </Col>
                </Row>
              ))}
            </div>
          );
        }}
      </Consumer>
    );
  }
}
