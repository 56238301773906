import React from "react";
import Section from "./../components/Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./../components/SectionHeader";
import InvoiceFrom from "./invoiceForm";
import Consumer from "../util/CartContext";

export default class CartList extends React.Component {
  constructor(props) {
    super(props);
    console.log("El estereofón no contamina necio!");
    this.state = {
      eInvoice: false,
      name: null,
      phone: null,
      email: null,
      province: null,
      canton: null,
      district: null,
      nameEinvoice: null,
      emailEinvoice: null,
      phoneEinvoice: null,
      idEinvoice: null,
      productList: [],
      total: 0
    };
  }

  handleSubmit = () => {
    //Show Payment form
    console.log("clicked");
  };

  addToCart = () => {
    console.log("works");
  };

  render() {
    return (
      <Section
        bg={this.props.bg}
        textColor={this.props.textColor}
        size={this.props.size}
        bgImage={this.props.bgImage}
        bgImageOpacity={this.props.bgImageOpacity}
      >
        <Container>
          <Consumer>
            {ctx => {
              return (
                <React.Fragment>
                  <SectionHeader
                    title={this.props.title}
                    subtitle={this.props.subtitle}
                    size={1}
                    spaced={true}
                  ></SectionHeader>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Código</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Precio unitario</th>
                        <th scope="col">Precio total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ctx.loop.map((item, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{item.code}</th>
                            <td>
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Basic example"
                              >
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  onClick={() =>
                                    ctx.addToCart({
                                      props: {
                                        code: item.code,
                                        title: item.title,
                                        price: item.price
                                      }
                                    })
                                  }
                                >
                                  +
                                </button>
                                <button
                                  type="button"
                                  disabled
                                  className="btn btn-light"
                                >
                                  {item.amount}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  onClick={() => ctx.removeFromCart({ item })}
                                >
                                  -
                                </button>
                              </div>
                            </td>
                            <td>{item.title}</td>
                            <td>${item.price}</td>
                            <td>${item.totalPrice}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="clearfix">
                    <hr className="my-3" />
                    <p className="display-4 float-right">
                      Total: ${ctx.total.toFixed(2)}
                    </p>
                  </div>
                  <hr className="my-3" />
                  <InvoiceFrom />
                </React.Fragment>
              );
            }}
          </Consumer>
        </Container>
      </Section>
    );
  }
}
