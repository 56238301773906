import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";

function NavbarCustom(props) {
  return (
    <Navbar bg={props.bg} variant={props.variant} expand={props.expand}>
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              className="d-inline-block align-top"
              src={props.logo}
              alt="Logo"
              height="100"
            ></img>
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle
          aria-controls="navbar-nav"
          className="border-0"
        ></Navbar.Toggle>
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav className="mr-1">
            {/* <LinkContainer to="/geofoam">
              <Nav.Link>Geofoam</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/arquitectura">
              <Nav.Link>Arquitectura</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/agricultura">
              <Nav.Link>Agricultura</Nav.Link>
            </LinkContainer> */}

            <LinkContainer to="/tienda">
              <Nav.Link>Tienda</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;
