import React, { useState } from "react";
import FormStatus from "./FormStatus";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import FormField from "./FormField";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

function ContactForm(props) {
  // State for input values
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Whether to show errors
  // We set to true if they submit and there are errors.
  // We only show errors after they submit because
  // it's annoying to see errors while typing.
  const [showErrors, setShowErrors] = useState(false);

  // Error array we'll populate
  let errors = [];

  // Function for fetching error for a field
  const getError = field => {
    return errors.find(e => e.field === field);
  };

  // Function to see if field is empty
  const isEmpty = val => val.trim() === "";

  // Add error if email empty
  if (isEmpty(email)) {
    errors.push({
      field: "email",
      message: "Por favor introduce tu correo electrónico"
    });
  }

  // Add error if message empty
  if (isEmpty(message)) {
    errors.push({
      field: "message",
      message: "¿Algún mensaje?"
    });
  }

  // Add error if name shown and empty
  if (props.showNameField) {
    if (isEmpty(name)) {
      errors.push({
        field: "name",
        message: "Please enter your name"
      });
    }
  }

  // Handle form submission
  const handleSubmit = e => {
    // If field errors then show them
    if (errors.length) {
      setShowErrors(true);
    } else {
      // Otherwise call onSubmit with form data
      if (props.onSubmit) {
        props.onSubmit({
          name,
          email,
          message
        });
      }
    }
  };

  return (
    <>
      {props.status && props.status.message && (
        <FormStatus
          type={props.status.type}
          message={props.status.message}
        ></FormStatus>
      )}

      <Form
        validated={showErrors}
        noValidate={true}
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Form.Row>
          {props.showNameField && (
            <Form.Group as={Col} xs={12} sm={6} controlId="formName">
              <FormField
                size={props.inputSize}
                value={name}
                type="text"
                placeholder="Name"
                required={true}
                onChange={e => setName(e.target.value)}
                error={showErrors && getError("name")}
              ></FormField>
            </Form.Group>
          )}

          <Form.Group
            as={Col}
            xs={12}
            sm={props.showNameField ? 6 : 12}
            controlId="formEmail"
          >
            <FormField
              size={props.inputSize}
              value={email}
              type="email"
              placeholder="Correo electrónico"
              required={true}
              error={showErrors && getError("email")}
              onChange={e => setEmail(e.target.value)}
            ></FormField>
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="formMessage">
          <FormField
            size={props.inputSize}
            value={message}
            type="textarea"
            placeholder="Hola! Busco información sobre...."
            required={true}
            error={showErrors && getError("message")}
            onChange={e => setMessage(e.target.value)}
            rows={5}
          ></FormField>
        </Form.Group>
        <Button
          variant={props.buttonColor}
          size={props.inputSize}
          type="submit"
          disabled={props.status.type === "pending"}
        >
          <span>{props.buttonText}</span>

          {props.status.type === "pending" && (
            <Spinner
              animation="border"
              size="sm"
              role="status"
              aria-hidden={true}
              className="ml-2"
            >
              <span className="sr-only">Enviando...</span>
            </Spinner>
          )}
        </Button>
      </Form>
    </>
  );
}

export default ContactForm;
