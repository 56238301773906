import React from "react";
import ReactDOM from "react-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  ElementsConsumer
} from "@stripe/react-stripe-js";
import Consumer from "../util/CartContext";

import styled from "styled-components";

export default class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waiting: false,
      success: false,
      paid: false
    };
  }

  handleSubmit = async event => {
    this.setState({ waiting: true });
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    // console.log(this.props.secret);

    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(
      this.props.secret.clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: "Jenny Rosen"
          }
        }
      }
    );

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
      this.setState({
        snackBarActive: true,
        snackBg: "red",
        snackBarMsg: "Error: " + result.error.message
      });
      setTimeout(() => {
        this.setState({ snackBarActive: false });
      }, 2500);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        var url =
          "https://us-central1-conceptos-5774b.cloudfunctions.net/conceptoscr/paymentSuccess";
        var body = {
          key: this.props.secret.clientKey
        };
        fetch(url, {
          method: "POST",
          body: JSON.stringify(body),
          headers: { "Content-Type": "application/json" }
        })
          .then(res => res.json())
          .then(json => {
            // console.log(json);
            this.setState({ success: true, waiting: false, paid: true });
            // console.log(states);
          });
        // this.setState({ success: true });
        console.log("succeded");
        this.setState({
          snackBarActive: true,
          snackBg: "green",
          snackBarMsg: "Pago exitoso!"
        });
        setTimeout(() => {
          this.setState({ snackBarActive: false });
        }, 2500);
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  render() {
    const { stripe } = this.props;

    return (
      <Consumer>
        {ctx => {
          return (
            <form onSubmit={this.handleSubmit} className="text-center my-5">
              <CardElement />
              <button
                type="submit"
                disabled={!stripe || !this.props.done}
                className="btn btn-lg btn-primary btn-block my-4 mx-auto"
              >
                {this.props.done
                  ? this.state.waiting
                    ? "Pagando..."
                    : this.state.paid
                    ? "Gracias"
                    : "Pagar"
                  : "Verifica la orden primero"}
              </button>

              {this.state.success ? (
                <div class="alert alert-success" role="alert">
                  <strong>Pago aprobado!</strong> Ya puedes venir a retirar tu
                  producto a nuestras oficinas.
                </div>
              ) : null}
            </form>
          );
        }}
      </Consumer>
    );
  }
}
