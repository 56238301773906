import React from "react";
import "./SectionHeader.scss";
import Consumer from "../util/CartContext";

function SectionHeader(props) {
  // Render nothing if no title or subtitle
  if (!props.title && !props.subtitle) {
    return null;
  }

  return (
    <Consumer>
      {(ctx) => {
        return (
          <header
            className={
              "SectionHeader" + (props.className ? ` ${props.className}` : "")
            }
          >
            {props.title && (
              <h1
                className={
                  "font-weight-bold" +
                  (props.subtitle && props.spaced ? " mb-4" : "") +
                  (!props.subtitle ? " mb-0" : "") +
                  (props.size ? ` h${props.size}` : "")
                }
              >
                {props.title}
              </h1>
            )}

            {props.subtitle && (
              <p className="SectionHeader__subtitle mb-0">{props.subtitle}</p>
            )}

            {props.msg && (
              <div>
                <br></br>
                <p className="SectionHeader__subtitle mb-0 text-success">
                  {props.msg}
                </p>
              </div>
            )}

            {props.pdfurl && (
              <a
                href={props.pdfurl}
                className="lead mt-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ficha técnica
              </a>
            )}

            {props.price && (
              <h3 className="mt-3">
                ${props.lamina ? ctx.laminaPrice : props.price}
              </h3>
            )}
            {props.product ? (
              props.lamina ? (
                <React.Fragment>
                  <label for="format" className="mt-3">
                    Formato y espesor de la lámina
                  </label>
                  <div class="input-group mt-1">
                    <select
                      class="custom-select"
                      id="inputGroupSelect04"
                      aria-label="Example select with button addon"
                      name="format"
                      value={ctx.selectedFormat}
                      onChange={(evt) => ctx.updateFormat(evt)}
                    >
                      <option value="10">
                        244cm x 122cm (4'x 8') - Construcción
                      </option>
                      <option value="200">
                        122cm x 61cm (4' x 2') - Cieloraso
                      </option>
                      <option value="4000">100cm x 50cm (40" x 20")</option>
                    </select>
                    <select
                      class="custom-select"
                      id="inputGroupSelect04"
                      aria-label="Example select with button addon"
                      name="thickness"
                      value={ctx.selectedThickness}
                      onChange={(evt) => ctx.updateFormat(evt)}
                    >
                      <option value="1">12mm (0.5")</option>
                      <option value="2">19mm (0.75")</option>
                      <option value="3">25mm (1")</option>
                      <option value="4">32mm (1.25")</option>
                      <option value="5">38mm (1.5")</option>
                      <option value="6">50mm (2")</option>
                      <option value="7">63mm (2.5")</option>
                      <option value="8">75mm (3")</option>
                      <option value="9">100mm (4")</option>
                      <option value="10">125mm (5")</option>
                      <option value="11">150mm (6")</option>
                      <option value="12">175mm (7")</option>
                      <option value="13">200mm (8")</option>
                    </select>
                    <div class="input-group-append">
                      <button
                        class="btn btn-success"
                        type="button"
                        onClick={() => ctx.addLamina()}
                      >
                        Al carrito
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <button
                  className="btn btn-success mt-3"
                  onClick={() => ctx.addToCart({ props })}
                >
                  Al carrito
                </button>
              )
            ) : null}
          </header>
        );
      }}
    </Consumer>
  );
}

export default SectionHeader;
