import React from "react";
import "./../styles/global.scss";
import NavbarCustom from "./../components/NavbarCustom";
import IndexPage from "./index";
import TiendaPage from "./tienda";
import GeofoamPage from "./geofoam";
import { Switch, Route, Router } from "./../util/router.js";
import ContactSection from "./../components/ContactSection";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import { CartProvider } from "./../util/CartContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_live_jz2DKAzki5wFYQfeIgoachUT");

function App(props) {
  return (
    <CartProvider>
      <Elements stripe={stripePromise}>
        <Router>
          <>
            <NavbarCustom
              bg="white"
              variant="light"
              expand="md"
              logo="https://firebasestorage.googleapis.com/v0/b/conceptos-5774b.appspot.com/o/webLayout%2Fmain_logo%2Flogo.svg?alt=media&token=4afe32ed-7b05-480e-bd99-d8c6316b77e5"
            ></NavbarCustom>

            <Switch>
              <Route exact path="/" component={IndexPage} />

              <Route exact path="/tienda" component={TiendaPage} />

              <Route exact path="/geofoam" component={GeofoamPage} />

              <Route
                component={({ location }) => {
                  return (
                    <div
                      style={{
                        padding: "50px",
                        width: "100%",
                        textAlign: "center"
                      }}
                    >
                      The page <code>{location.pathname}</code> could not be
                      found.
                    </div>
                  );
                }}
              />
            </Switch>

            <ContactSection
              bg="white"
              textColor="dark"
              size="md"
              bgImage=""
              bgImageOpacity={1}
              title="¡Háblanos!"
              subtitle="Si tienes dudas, sugerencias o críticas, no esperes más en hablarnos para conocernos. Es un placer conocerte :)"
              buttonText="Enviar"
              buttonColor="success"
              showNameField={false}
              inputSize="md"
              embedSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.4021870232896!2d-84.15492708453274!3d9.983598092862437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa0fbad4072b0b3%3A0x814d42f57ec8db89!2sConceptos.cr%20SA!5e0!3m2!1sen!2sat!4v1582999409378!5m2!1sen!2sat"
            ></ContactSection>
            <Footer
              bg="white"
              textColor="dark"
              size="md"
              bgImage=""
              bgImageOpacity={1}
              description="A short description of what you do here"
              copyright="© 2020 Conceptos.cr"
              logo="https://firebasestorage.googleapis.com/v0/b/conceptos-5774b.appspot.com/o/webLayout%2Fmain_logo%2Flogo.svg?alt=media&token=4afe32ed-7b05-480e-bd99-d8c6316b77e5"
            ></Footer>
          </>
        </Router>
      </Elements>
    </CartProvider>
  );
}

export default App;
