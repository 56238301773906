import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Stats from "./Stats";

function StatsSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <Stats
          items={[
            {
              title: "EPS reciclado",
              stat: "14,456 Toneladas"
            },
            {
              title: "Paises aportando",
              stat: "7"
            },
            {
              title: "Energía ahorrada",
              stat: "456 GigaWatt"
            },
            {
              title: "Años aportando",
              stat: "20"
            }
          ]}
        ></Stats>
      </Container>
    </Section>
  );
}

export default StatsSection;
