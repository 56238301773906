import React from "react";
import Welcome from "./../components/Welcome";
import Trinity from "./../components/Trinity";
import ProductList2 from "./../components/ProductList2";
import CartList from "./../components/cartList";
import Container from "react-bootstrap/Container";
import Snackbar from "./../components/Snackbar";

import * as firebase from "firebase/app";
import "firebase/database";
// import { Snackbar } from "./../components/Snackbar";

export default class TiendaPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: false,
    };
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: "AIzaSyB4kaxKiEVS_C_TpjeEg7xHxhvmTbUUyJI",
        authDomain: "conceptos-5774b.firebaseapp.com",
        databaseURL: "https://conceptos-5774b.firebaseio.com",
        projectId: "conceptos-5774b",
        storageBucket: "conceptos-5774b.appspot.com",
        messagingSenderId: "370910585651",
        appId: "1:370910585651:web:24fec9ccf3cf7dd3021446",
      });
    }
  }

  render() {
    return (
      <>
        <Snackbar></Snackbar>
        <Container>
          <div class="alert alert-danger" role="alert">
            Esta tienda <strong>no incluye flete</strong>. Si necesitas que te
            entreguemos el producto por favor contáctanos!
          </div>
        </Container>

        <Welcome
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title="Bienvenido a nuestra tienda en línea"
          subtitle="Aqui conseguirás nuestra selección de productos más comunes. Si necesitas algo más específico o hecho a la medida, te ayudamos con gusto, sólo contáctanos abajo y listo."
          buttonText="Get Started"
          buttonColor="primary"
          image="https://firebasestorage.googleapis.com/v0/b/conceptos-5774b.appspot.com/o/react%2Fshopping.svg?alt=media&token=c5f28071-d66f-46e8-8487-76ea14e110d1"
          onButtonClick={() => console.log("loading")}
        ></Welcome>
        <Trinity
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
        ></Trinity>
        <ProductList2
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title="Productos"
          subtitle="Todos los precios en USD e incluyen el I.V.A."
          onButtonClick={this._showSnackbarHandler}
        ></ProductList2>
        <CartList
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title="Carrito"
          subtitle="Aqui están los productos que gustes comprar."
        ></CartList>
      </>
    );
  }
}
