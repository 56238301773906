import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Card from "react-bootstrap/Card";
import Features from "./Features";

function FeaturesSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
        ></SectionHeader>
        <Card>
          <Features
            columns={2}
            imageRatio="4by3"
            items={[
              {
                title: "Reciclamos",
                body:
                  "Reciclamos el estereofón que tengas en casa. lo recibimos las 24 horas del día TODOS los días del año.",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/conceptos-5774b.appspot.com/o/react%2Frecycle_card_1.svg?alt=media&token=fa506ca6-e6d3-426d-8304-1bf9c2af7627"
              },
              {
                title: "Ahorramos",
                body:
                  "El ahorro de la energía es tan importante como su fuente. Reducimos el consumo con nuestros sistemas de aislamiento.",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/conceptos-5774b.appspot.com/o/react%2Fsave_energy_card_2.svg?alt=media&token=0f16f76f-1189-410f-856d-c00377fe5384"
              },
              {
                title: "Educamos",
                body:
                  "El primer paso para el desarrollo es la educación. Con nuestras capacitaciones podrás aprender todo sobre el estereofón.",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/conceptos-5774b.appspot.com/o/react%2Fcard_4_edu.svg?alt=media&token=969fe852-4e4f-4733-b2ca-a53d8e69c90f"
              },
              {
                title: "Aportamos",
                body:
                  "Todos juntos podemos poner de nuestra parte para avanzar. Nosotros aportamos nuestro granito de arena cada día.",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/conceptos-5774b.appspot.com/o/react%2Fcard_3.svg?alt=media&token=10dc32b8-459b-4cdb-b63d-16e658d33f5f"
              }
            ]}
          ></Features>
        </Card>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
