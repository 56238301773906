import React, { useState } from "react";
import ContactForm from "./ContactForm";
import contact from "./../util/contact.js";

function Contact(props) {
  const [status, setStatus] = useState({ type: "none" });

  const onSubmit = ({ name, email, message }) => {
    setStatus({ type: "pending" });

    contact.submit({ name, email, message }).then(() => {
      setStatus({
        type: "success",
        message: "Gracias por tu mensaje. Te contactaremos pronto!"
      });
    });
  };
  return (
    <ContactForm onSubmit={onSubmit} status={status} {...props}></ContactForm>
  );
}

export default Contact;
