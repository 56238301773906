import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Faq from "./Faq";

function FaqSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        ></SectionHeader>
        <Faq
          items={[
            {
              question: "¿Qué es el EPS?",
              answer:
                "El estereofón producido en Conceptos.cr es un poliestireno expandido (EPS). Este plástico es una perla que con la acción del calor se expande creando perlas de distintos tamaños. Estas se colocan en un molde y con vapor de agua se consigue la adherencia entre si, creando la forma deseada, con distintas durezas o densidades. "
            },
            {
              question: "¿Cómo se recicla el EPS?",
              answer:
                "Los lazos generados durante la producción se pueden romper mediante molinos, para así volver a unir las perlas formando una pieza distinta. Esto significa que se puede reciclar las veces deseadas y no hay necesidad de mezclar el estereofón con los desechos tradicionales. Todo el EPS que recibimos en Conceptos.cr se recicla y se usa para la producción de materiales para la construcción de viviendas, edificios y variedad de infraestructura. Gracias a la ayuda de todos, los ciudadanos costarricenses podemos lograr que todo el estereofón producido sea reciclado en su totalidad."
            },
            {
              question: "¿Qué dice la nueva ley N.0 9703?",
              answer:
                "Las fábricas de estereofón que operamos en Costa Rica, hacemos una gran variedad de productos para distintas aplicaciones en construcción, embalaje industrial y agropecuario. Esta amplia gama de productos no incluye ninguno de los afectados por adición a la ley 8839, toda la línea de productos de estereofón está a la disposición, para seguir ahorrando energía y recursos, y seguir protegiendo sus productos (pescado, frutas, chocolates, medicinas, etc.). Adicional, todos estos productos de estereofón (poliestireno expandido o EPS) son reciclables y Conceptos.cr los recicla. Naturalmente Conceptos.cr también recicla todo el estereofón que logre captar de los embalajes de los productos de importación, como electrodomésticos, medicamentos y equipos médicos, cajas de uvas, etc..."
            }
          ]}
        ></Faq>
      </Container>
    </Section>
  );
}

export default FaqSection;
