import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Features3 from "./Features3";
import SectionHeader from "./SectionHeader";

function ProductList2(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          msg="Todas las hieleras vienen con tapa :)"
          size={1}
          spaced={true}
        ></SectionHeader>
        <Features3 onButtonClick={props.onButtonClick}></Features3>
      </Container>
    </Section>
  );
}

export default ProductList2;
