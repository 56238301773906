import React, { useState } from "react";
import Section from "./../components/Section";
import Container from "react-bootstrap/Container";
// import SectionHeader from "./../components/SectionHeader";
import Consumer from "../util/CartContext";
import CheckoutForm from "./stripeElement";
import {
  CardElement,
  Elements,
  ElementsConsumer
} from "@stripe/react-stripe-js";

export default class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      Ename: "",
      Eemail: "",
      Eid: "",
      Ephone: "",
      needEinvoice: false,
      states: [],
      selectedState: "",
      counties: [],
      selectedCounty: "",
      districts: [],
      selectedDistrict: ""
    };
  }

  componentDidMount() {
    fetch("https://ubicaciones.paginasweb.cr/provincias.json")
      .then(res => res.json())
      .then(json => {
        // console.log(json);
        var states = Object.values(json);
        this.setState({ states: states });
        // console.log(states);
      });
  }

  updateCounties = evt => {
    let selected = evt.target.value;
    this.setState({ selectedState: selected });
    // console.log(selected);
    let index = this.state.states.indexOf(selected);
    fetch(
      "https://ubicaciones.paginasweb.cr/provincia/" +
        (index + 1) +
        "/cantones.json"
    )
      .then(res => res.json())
      .then(json => {
        // console.log(json);
        var counties = Object.values(json);
        this.setState({ counties: counties });
        // console.log(counties);
      });
  };

  updateDistrict = evt => {
    let selected = evt.target.value;
    this.setState({ selectedCounty: selected });
    // console.log(selected);
    let StateIndex = this.state.states.indexOf(this.state.selectedState);
    let CountyIndex = this.state.counties.indexOf(this.state.selectedCounty);
    fetch(
      "https://ubicaciones.paginasweb.cr/provincia/" +
        (StateIndex + 1) +
        "/canton/" +
        (CountyIndex + 1) +
        "/distritos.json"
    )
      .then(res => res.json())
      .then(json => {
        // console.log(json);
        var districts = Object.values(json);
        this.setState({ districts: districts });
        // console.log(districts);
      });
  };

  updateDistrictName = evt => {
    let selected = evt.target.value;
    this.setState({ selectedDistrict: selected });
  };

  updateNameValue(evt) {
    var name = evt.target.name;
    var state = { ...this.state };
    // console.log(state);
    state[name] = evt.target.value;
    this.setState(state);
  }

  render() {
    return (
      <Section
        bg={this.props.bg}
        textColor={this.props.textColor}
        size={this.props.size}
        bgImage={this.props.bgImage}
        bgImageOpacity={this.props.bgImageOpacity}
      >
        <Container>
          <Consumer>
            {ctx => {
              return (
                <React.Fragment>
                  <p className="h3 my-3">Tus datos para contactarte</p>
                  <form>
                    <div className="row mb-4">
                      <div className="col-sm mb-2">
                        <input
                          required
                          type="text"
                          className="form-control text-capitalize"
                          placeholder="Nombre"
                          name="name"
                          value={this.state.name}
                          onChange={evt => this.updateNameValue(evt)}
                        />
                      </div>
                      <div className="col-sm mb-2">
                        <input
                          required
                          type="email"
                          className="form-control"
                          placeholder="Correo electrónico"
                          name="email"
                          value={this.state.email}
                          onChange={evt => this.updateNameValue(evt)}
                        />
                      </div>
                      <div className="col-sm mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Teléfono (opcional)"
                          name="phone"
                          value={this.state.phone}
                          onChange={evt => this.updateNameValue(evt)}
                        />
                      </div>
                    </div>
                    <div className="form-group form-check">
                      <input
                        checked={ctx.invoiceData.needEinvoice}
                        onClick={() => ctx.invoiceData.checkBox()}
                        type="checkbox"
                        className="form-check-input"
                        id="eInvoiceCheck"
                      />
                      <label className="form-check-label" for="exampleCheck1">
                        Factura electrónica
                      </label>
                    </div>
                    <hr className="my-4" />
                    <p className="h3 my-3">Datos de la factura electrónica</p>

                    <div className="row">
                      <div className="col-sm mb-2">
                        <div className="form-group">
                          <label for="nameEinvoice">Nombre</label>
                          <input
                            required={ctx.invoiceData.needEinvoice}
                            disabled={!ctx.invoiceData.needEinvoice}
                            type="text"
                            className="form-control"
                            id="nameEinvoice"
                            placeholder="Nombre completo"
                            name="Ename"
                            value={this.state.Ename}
                            onChange={evt => this.updateNameValue(evt)}
                          />
                        </div>
                      </div>
                      <div className="col-sm mb-2">
                        <div className="form-group">
                          <label for="exampleInputEmail1">
                            Correo electrónico
                          </label>
                          <input
                            required={ctx.invoiceData.needEinvoice}
                            disabled={!ctx.invoiceData.needEinvoice}
                            type="email"
                            className="form-control"
                            id="emailEinvoice"
                            aria-describedby="emailHelp"
                            placeholder="Correo electrónico"
                            name="Eemail"
                            value={this.state.Eemail}
                            onChange={evt => this.updateNameValue(evt)}
                          />
                          <small
                            id="emailHelp"
                            className="form-text text-muted"
                          >
                            Correo electrónico para la factura electrónica
                          </small>
                        </div>
                      </div>
                      <div className="col-sm mb-2">
                        <div className="form-group">
                          <label for="idEinvoice">
                            Cédula o identificación
                          </label>
                          <input
                            required={ctx.invoiceData.needEinvoice}
                            disabled={!ctx.invoiceData.needEinvoice}
                            type="number"
                            className="form-control"
                            id="idEinvoice"
                            placeholder="Cédula o identificación"
                            name="Eid"
                            value={this.state.Eid}
                            onChange={evt => this.updateNameValue(evt)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm mb-2">
                        <div className="form-group">
                          <label for="phoneEinvoice">Teléfono</label>
                          <input
                            required={ctx.invoiceData.needEinvoice}
                            disabled={!ctx.invoiceData.needEinvoice}
                            type="text"
                            className="form-control"
                            id="phoneEinvoice"
                            placeholder="Teléfono"
                            name="Ephone"
                            value={this.state.Ephone}
                            onChange={evt => this.updateNameValue(evt)}
                          />
                        </div>
                      </div>
                      <div className="col-sm mb-2">
                        <div class="form-group">
                          <label for="exampleFormControlSelect1">
                            Provincia
                          </label>
                          <select
                            required={ctx.invoiceData.needEinvoice}
                            class="form-control"
                            id="exampleFormControlSelect1"
                            disabled={!ctx.invoiceData.needEinvoice}
                            name="Estate"
                            value={this.state.selectedState}
                            onChange={evt => this.updateCounties(evt)}
                          >
                            <option selected>Seleccione...</option>
                            {this.state.states.map((item, i) => {
                              return <option key={i}>{item}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm mb-2">
                        <div class="form-group">
                          <label for="cantonEinvoice">Cantón</label>
                          <select
                            required={ctx.invoiceData.needEinvoice}
                            class="form-control"
                            id="cantonEinvoice"
                            disabled={!ctx.invoiceData.needEinvoice}
                            name="Ecounty"
                            value={this.state.selectedCounty}
                            onChange={evt => this.updateDistrict(evt)}
                          >
                            <option selected>Seleccione...</option>
                            {this.state.counties.map((item, i) => {
                              return <option key={i}>{item}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm mb-2">
                        <div class="form-group">
                          <label for="districtEinvoice">Distrito</label>
                          <select
                            required={ctx.invoiceData.needEinvoice}
                            class="form-control"
                            id="districtEinvoice"
                            disabled={!ctx.invoiceData.needEinvoice}
                            name="Edistrict"
                            value={this.state.selectedDistrict}
                            onChange={evt => this.updateDistrictName(evt)}
                          >
                            <option selected>Seleccione...</option>
                            {this.state.districts.map((item, i) => {
                              return <option key={i}>{item}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <button
                      onClick={() => ctx.invoiceData.handleSubmit(this.state)}
                      className="btn btn-success"
                      type="button"
                      disabled={ctx.done || ctx.verifying}
                    >
                      {!ctx.done
                        ? ctx.verifying
                          ? "Verificando..."
                          : "Verificar orden"
                        : "Gracias, ya puedes pagar"}
                    </button>
                  </form>
                  <hr className="my-3" />
                  <p className="h3 my-3">Datos de tu tarjeta</p>
                  <ElementsConsumer>
                    {({ stripe, elements }) => {
                      return (
                        <CheckoutForm
                          stripe={stripe}
                          elements={elements}
                          secret={ctx}
                          done={ctx.done}
                        />
                      );
                    }}
                  </ElementsConsumer>
                </React.Fragment>
              );
            }}
          </Consumer>
        </Container>
      </Section>
    );
  }
}
