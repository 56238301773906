import React from "react";
import HeroSection from "./../components/HeroSection";
import Maintainance from "./../components/Maintainance";
import Table from "react-bootstrap/Table";
import { useRouter } from "./../util/router.js";
import "./geofoam.scss";

function GeofoamPage(props) {
  const router = useRouter();

  return (
    <>
      <HeroSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="GeoFoam"
        subtitle="GeoFoam de Conceptos.cr se usa en proyectos con necesidad de relleno de gran volúmen pero poco peso para reducir el efecto sobre el suelo o la estructura base."
        buttonText=""
        buttonColor="primary"
        image="https://firebasestorage.googleapis.com/v0/b/conceptos-5774b.appspot.com/o/react%2FIsometric%20Auger%20Is%20Digging%20The%20Ground%20Free%20Vector.svg?alt=media&token=168f4a4a-5c9b-4ce4-8d70-bca11350b76c"
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push("/pricing");
        }}
      ></HeroSection>

      <Maintainance
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Propiedades y precios"
        subtitle={false}
      ></Maintainance>
      <div className="GeofoamPage__no-classname">
        <Table striped={true}>
          <thead>
            <tr>
              <th>Propiedad</th>
              <th>Unidad</th>
              <th>EPS 8</th>
              <th>EPS 10</th>
              <th>EPS 12</th>
              <th>EPS 16</th>
              <th>EPS 21</th>
              <th>EPS 24</th>
              <th>EPS 32</th>
              <th>EPS 40</th>
              <th>EPS 50</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Densidad</td>
              <td>
                kg/m3 <br />
                lb/ft3
              </td>
              <td>
                8 <br /> 0.5
              </td>
              <td>
                10 <br />
                0.625
              </td>
              <td>
                12 <br />
                0.75
              </td>
              <td>
                16 <br />
                1.00
              </td>
              <td>
                21 <br />
                1.25
              </td>
              <td>
                24 <br />
                1.50
              </td>
              <td>
                32 <br />
                2.00
              </td>
              <td>
                40 <br />
                2.5
              </td>
              <td>
                50 <br />
                3.125
              </td>
            </tr>
            <tr>
              <td>Resistencia Compresión en 1% deformación mínima</td>
              <td>
                psi <br /> psf <br /> kPa
              </td>
              <td>
                1.2 <br /> 167 <br /> 8
              </td>
              <td>
                1.7 <br /> 251 <br /> 12
              </td>
              <td>
                2.6 <br /> 376 <br /> 18
              </td>
              <td>
                4.5 <br /> 647 <br /> 31
              </td>
              <td>
                7.0 <br /> 1003 <br /> 48
              </td>
              <td>
                8.4 <br /> 1211 <br /> 58
              </td>
              <td>
                12.2 <br /> 1754 <br /> 84
              </td>
              <td>
                15.7 <br /> 2256 <br /> 108
              </td>
              <td>
                20.6 <br /> 2966 <br /> 142
              </td>
            </tr>
            <tr>
              <td>Módulo elástico mínimo</td>
              <td>
                psi <br />
                kPa
              </td>
              <td>
                120 <br /> 800
              </td>
              <td>
                170 <br /> 1200
              </td>
              <td>
                250 <br /> 1700
              </td>
              <td>
                450 <br /> 3100
              </td>
              <td>
                700 <br /> 4800
              </td>
              <td>
                840 <br /> 5800
              </td>
              <td>
                1220 <br /> 8400
              </td>
              <td>
                1570 <br /> 10800
              </td>
              <td>
                2030 <br /> 14000
              </td>
            </tr>
            <tr>
              <td>Fuerza de flexión mínima</td>
              <td>
                psi <br />
                kPa
              </td>
              <td>
                5.1 <br /> 35
              </td>
              <td>
                8.0 <br /> 55
              </td>
              <td>
                13.1 <br /> 90
              </td>
              <td>
                26.8 <br /> 185
              </td>
              <td>
                34.1 <br /> 235
              </td>
              <td>
                39.9 <br /> 275
              </td>
              <td>
                52.9 <br /> 365
              </td>
              <td>
                63.8 <br /> 440
              </td>
              <td>
                78.3 <br /> 540
              </td>
            </tr>
            <tr>
              <td>Absorción de agua en inmersión total, máxima</td>
              <td>vol. %</td>
              <td>5</td>
              <td>5</td>
              <td>4</td>
              <td>4</td>
              <td>4</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
            </tr>
            <tr>
              <td>Fuerza de flotación</td>
              <td>
                kg/m3 <br /> lb/ft3
              </td>
              <td>
                >940
                <br />
                >58.7
              </td>
              <td>
                >940
                <br />
                >58.7
              </td>
              <td>
                >935
                <br />
                >58.3
              </td>
              <td>
                >935
                <br />
                >58.3
              </td>
              <td>
                >935
                <br />
                >58.3
              </td>
              <td>
                >935
                <br />
                >58.3
              </td>
              <td>
                >930
                <br />
                >58
              </td>
              <td>
                >930
                <br />
                >58
              </td>
              <td>
                >930
                <br />
                >58
              </td>
            </tr>
            <tr className="bg-warning">
              <td>Precio por m3 - sin impuesto</td>
              <td>USD</td>
              <td>22.85</td>
              <td>26.18</td>
              <td>29.5</td>
              <td>39.97</td>
              <td>54.01</td>
              <td>60.91</td>
              <td>81.85</td>
              <td>104.7</td>
              <td>135.66</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default GeofoamPage;
