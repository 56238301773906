import React, { Component, createContext } from "react";
import * as firebase from "firebase/app";
import "firebase/database";
// Provider and Consumer are connected through their "parent" context
const { Provider, Consumer } = createContext();

// Provider will be exported wrapped in ConfigProvider component.
class CartProvider extends Component {
  constructor(props) {
    super(props);
    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey: "AIzaSyB4kaxKiEVS_C_TpjeEg7xHxhvmTbUUyJI",
        authDomain: "conceptos-5774b.firebaseapp.com",
        databaseURL: "https://conceptos-5774b.firebaseio.com",
        projectId: "conceptos-5774b",
        storageBucket: "conceptos-5774b.appspot.com",
        messagingSenderId: "370910585651",
        appId: "1:370910585651:web:24fec9ccf3cf7dd3021446",
      });
    }
    this.state = {
      cartList: [],
      cart: {},
      loop: [],
      test: "testing 1..2..3..",
      addToCart: this.addNow,
      removeFromCart: this.removeNow,
      total: 0,
      invoiceData: {
        name: "",
        email: "",
        phone: "",
        Ename: "",
        Eemail: "",
        Eid: "",
        Ephone: "",
        Estate: "",
        Ecounty: "",
        Edistrict: "",
        needEinvoice: false,
        checkBox: this.checkBox,
        handleSubmit: this.handleSubmit,
      },
      snackbar: {
        isActive: false,
        openSnackbar: this.openSnackBar,
        message: "",
      },
      clientSecret: "",
      done: false,
      verifying: false,
      clientKey: "",
      animationDone: this.finishAnimation,
      AnimationFinished: false,
      snackBarActive: false,
      snackBg: "",
      snackBarMsg: "",
      selectedFormat: "10",
      selectedThickness: "6",
      updateFormat: this.updateFormat,
      laminaPrice: 9.32,
      lamPriceObj: {},
      currentlySelectedLam: {},
      addLamina: this.addLamina,
      pushedLaminas: [],
    };
  }

  componentDidMount() {
    firebase
      .database()
      .ref("/products/lamina/price")
      .once("value", (data) => {
        this.setState({ lamPriceObj: data.val() });
      });
  }

  addLamina = () => {
    console.log(
      "Current price:",
      this.state.laminaPrice,
      this.state.lamPriceObj
    );
    let format = Number(this.state.selectedFormat);
    let thickness = this.state.selectedThickness;

    let name = "";

    console.log(typeof format);

    switch (format) {
      case 10:
        console.log("244cm x 122cm (4'x 8') - Construcción");
        name += "244cm x 122cm (4'x 8') - Construcción";
        break;
      case 200:
        console.log("122cm x 61cm (4' x 2') - Cieloraso");
        name += "122cm x 61cm (4' x 2') - Cieloraso";
        break;
      case 4000:
        console.log('100cm x 50cm (40" x 20")');
        name += '100cm x 50cm (40" x 20")';
        break;
      default:
        console.log("Default");
        break;
    }

    switch (thickness) {
      case "1":
        name += '- 12mm (0.5")';
        break;
      case "2":
        name += '- 19mm (0.75")';
        break;
      case "3":
        name += '- 25mm (1")';
        break;
      case "4":
        name += '- 32mm (1.25")';
        break;
      case "5":
        name += '- 38mm (1.5")';
        break;
      case "6":
        name += '- 50mm (2")';
        break;
      case "7":
        name += '- 63mm (2.5")';
        break;
      case "8":
        name += '- 75mm (3")';
        break;
      case "9":
        name += '- 100mm (4")';
        break;
      case "10":
        name += '- 125mm (5")';
        break;
      case "11":
        name += '- 150mm (6")';
        break;
      case "12":
        name += '- 175mm (7")';
        break;
      case "13":
        name += '- 200mm (8")';
        break;
      default:
        break;
    }

    console.log(name);
    this.addNow({
      props: {
        price: this.state.laminaPrice,
        title: name,
        code: "98IJ" + format * Number(thickness),
      },
    });
  };

  updateFormat = (evt) => {
    let format = this.state.selectedFormat;
    let thickness = this.state.selectedThickness;
    console.log(evt.target.name, evt.target.value);
    if (evt.target.name === "format") {
      format = evt.target.value;
      this.setState({ selectedFormat: evt.target.value });
    } else if (evt.target.name === "thickness") {
      thickness = evt.target.value;
      this.setState({ selectedThickness: evt.target.value });
    }

    let priceValue = format * thickness;

    console.log(format, thickness, priceValue);

    this.setState({ laminaPrice: this.state.lamPriceObj[priceValue] });
  };

  handleSubmit = (formData) => {
    if (Object.keys(this.state.cart).length === 0) {
      this.setState({
        verifying: false,
        snackBarActive: true,
        snackBg: "red",
        snackBarMsg: "No hay productos en el carrito",
      });
      setTimeout(() => {
        this.setState({ snackBarActive: false });
      }, 2500);
      return;
    }
    console.log(formData);
    if (!formData.email || !formData.name) {
      this.setState({
        snackBarActive: true,
        snackBg: "red",
        snackBarMsg: "Faltan espacios requeridos",
      });
      setTimeout(() => {
        this.setState({ snackBarActive: false });
      }, 2500);
      console.log("missing something...");
      return;
    }

    console.log(formData);

    console.log(this.state.cart);

    this.setState({ verifying: true });

    //Validate formData here

    //submit Payment intent with cart
    var body = {};
    body.cart = this.state.cart;
    body.clientData = formData;
    console.log(body);

    var url =
      "https://us-central1-conceptos-5774b.cloudfunctions.net/conceptoscr/paymentIntent";
    fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if (json.error) {
          console.log("Error on server");
          this.setState({
            snackBarActive: true,
            snackBg: "red",
            snackBarMsg: "Error interno, intenta en unos minutos...",
          });
          setTimeout(() => {
            this.setState({ snackBarActive: false });
          }, 2500);
          return;
        }
        console.log("No errors found..");
        this.setState({ clientKey: json.key });
        this.setState({
          clientSecret: json.secret,
          clientKey: json.key,
          done: true,
          verifying: false,
          snackBarActive: true,
          snackBg: "blue",
          snackBarMsg: "Ya puedes introducir los datos de tu tarjeta.",
        });
        setTimeout(() => {
          this.setState({ snackBarActive: false });
        }, 2500);
      })
      .catch((err) => {
        this.setState({
          snackBarActive: true,
          snackBg: "red",
          snackBarMsg: "Error interno, intenta en unos minutos...",
        });
        setTimeout(() => {
          this.setState({ snackBarActive: false });
        }, 2500);
      });

    //toggle Card input fields for payment

    //Send email
  };

  checkBox = (status) => {
    var invoiceData = { ...this.state.invoiceData };
    invoiceData.needEinvoice = !invoiceData.needEinvoice;
    this.setState({ invoiceData });
  };

  removeNow = (product) => {
    console.log("removing now", product);

    let cart = this.state.cart;
    // console.log(cart);
    try {
      if (cart[product.item.title].amount === 1) {
        //remove item
        console.log("0 left");
        delete cart[product.item.title];
        let total = this.state.total;
        total = total - Number(product.item.price);
        this.setState({ total: total });
        const items = this.state.cartList;
        const valueToRemove = product.item.title;
        const filteredItems = items.filter((item) => item !== valueToRemove);
        console.log(filteredItems);
        this.setState({ cartList: filteredItems });
      } else if (cart[product.item.title].amount > 1) {
        cart[product.item.title].amount = cart[product.item.title].amount - 1;
        cart[product.item.title].totalPrice = (
          Number(cart[product.item.title].totalPrice) -
          Number(product.item.price)
        ).toFixed(2);
        let total = this.state.total;
        total = total - Number(product.item.price);
        this.setState({ total: total });
      }
      let iterable = Object.keys(cart);

      let loop = iterable.map((key) => {
        return {
          amount: cart[key].amount,
          title: key,
          price: cart[key].price,
          totalPrice: cart[key].totalPrice,
          code: cart[key].code,
        };
      });

      this.setState({ loop: loop });
      //   console.log(cart);
    } catch (e) {
      console.log(e);
    }
  };

  addNow = (product) => {
    // console.log("added to cart");
    // console.dir(product);
    let price = Number(product.props.price);
    let code = product.props.code;
    let title = product.props.title;
    let total = this.state.total;

    total = total + price;

    this.setState({ total: Number(total) });

    let cart = this.state.cart;
    let cartList = this.state.cartList;

    if (cartList.includes(title)) {
      cart[title] = {
        title: title,
        amount: cart[title].amount + 1,
        price: price.toFixed(2),
        totalPrice: (price * (cart[title].amount + 1)).toFixed(2),
        code: code,
      };
    } else {
      cartList.push(title);
      cart[title] = {
        title: title,
        amount: 1,
        price: price.toFixed(2),
        totalPrice: price.toFixed(2),
        code: code,
      };
    }
    let iterable = Object.keys(cart);

    let loop = iterable.map((key) => {
      return {
        amount: cart[key].amount,
        title: key,
        price: cart[key].price,
        totalPrice: cart[key].totalPrice,
        code: cart[key].code,
      };
    });

    this.setState({
      loop: loop,
      snackBarActive: true,
      snackBg: "green",
      snackBarMsg: "Agregado: " + title,
    });
    setTimeout(() => {
      this.setState({ snackBarActive: false });
    }, 2500);
  };

  finishAnimation = () => {
    console.log("finishing animation");
    this.setState({ AnimationFinished: true });
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export { CartProvider };

export default Consumer;
