import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Features2 from "./Features2";

function Trinity(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <Features2
          items={[
            {
              title: "Empaque",
              subtitle:
                "Hieleras de todos lo tamaños. Cuadradas y redondas. Con tu logotipo o blancas. ¡Liguistas o saprisitas! Como las necesites, aqui tenemos todo.",
              iconClass: "fas fa-box-open",
              iconColor: "warning"
            },
            {
              title: "Construcción",
              subtitle:
                "Entrepiso, aislamiento térmico o sónico, páneles prefabricados, bovedillas para entrepiso, bloques gigantes para GEOfón. Para aquellos que construyen nuestro mundo: ¡los tenemos cubiertos!",
              iconClass: "fas fa-building",
              iconColor: "primary"
            },
            {
              title: "Agropecuario",
              subtitle:
                "Semilleros, macetas, bandejas para hidroponía, hieleras para transporte frío o caliente, cajas hechas a la medida para protección de productos, sistemas de control de flujo. Agricultores: estamos a su servicio.",
              iconClass: "fas fa-seedling",
              iconColor: "success"
            }
          ]}
        ></Features2>
      </Container>
    </Section>
  );
}

export default Trinity;
