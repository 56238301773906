import React from "react";
import HeroSection from "./../components/HeroSection";
import VideoSection from "./../components/VideoSection";
import FeaturesSection from "./../components/FeaturesSection";
import StatsSection from "./../components/StatsSection";
import FaqSection from "./../components/FaqSection";
import WhatsappSection from "./../components/Whatsapp";
import WarningSection from "./../components/warningSection";
import { useRouter } from "./../util/router.js";

function IndexPage(props) {
  const router = useRouter();

  return (
    <>
      <HeroSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Bienvenido al mundo del estereofón 100% reciclable"
        subtitle="Con más de 20 años de experiencia, hemos logrado establecer nuestra reputación como expertos en el mundo del EPS. Aparte de nuestro producto, ofrecemos capacitaciones y asesorías."
        buttonText="Ir a tienda"
        buttonColor="success"
        image="https://firebasestorage.googleapis.com/v0/b/conceptos-5774b.appspot.com/o/react%2Frecycle.svg?alt=media&token=eab6c083-ae7b-45d6-b998-b49b366c486f"
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push("/tienda");
        }}
      ></HeroSection>
      <WarningSection
        bg="white"
        textColor="dark"
        size="md"
        bgImageOpacity={1}
        title="¡Advertencia!"
        subtitle="La recepción de estereofón de uso doméstico, para ser reciclado, es gratuito para personas físicas. A nivel institucional tiene un costo de $100 (I.V.A. incluido) por m³ de EPS para reciclar."
        image="warning.svg"
      ></WarningSection>
      <WhatsappSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Nuestro Whatsapp"
        image="whatsappweb.png"
        subtitle="Escribenos directo por Whatsapp para ahorrar tiempo!"
      ></WhatsappSection>
      <VideoSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Así nos presentamos ;)"
        subtitle=""
        embedUrl="https://www.youtube.com/embed/ZbE4tSbMlmA"
      ></VideoSection>
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Conócenos"
        subtitle="Nuestras formas de aportar a la sociedad y economía sostenible."
      ></FeaturesSection>
      <StatsSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
      ></StatsSection>
      <FaqSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Preguntas frecuentes"
        subtitle="¡Si tienes alguna otra duda no esperes en contactarnos!"
      ></FaqSection>
    </>
  );
}

export default IndexPage;
