import React, { PureComponent } from "react";
import Styles from "./Snackbar.module.css";
import Consumer from "../util/CartContext";

export default class Snackbar extends PureComponent {
  render() {
    return (
      <Consumer>
        {ctx => {
          return (
            <div
              style={{ backgroundColor: ctx.snackBg }}
              className={
                ctx.snackBarActive
                  ? [Styles.snackbar, Styles.show].join(" ")
                  : Styles.snackbar
              }
            >
              {ctx.snackBarMsg}
            </div>
          );
        }}
      </Consumer>
    );
  }
}
